import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'virtual:svg-icons-register'
import Cookies from 'js-cookie'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'
import * as kiwi from '@/kiwi'
import SvgIcon from '@/components/svg_icon/svg_icon.vue'
import BuildInfo from '@/components/build_info.vue'

const app = createApp(App)

if (kiwi.env.viteIsProd) {
  Sentry.init({
    app,
    dsn: 'https://970eea1e4d8e0f0d42780b8ed9a5ed27@o4507842553905152.ingest.de.sentry.io/4507842611183696',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(createPinia())
app.use(router)
app.use(kiwi.i18n)
app.component('SvgIcon', SvgIcon)
if (!kiwi.env.viteIsProd) {
  app.component('BuildInfo', BuildInfo)
}
app.directive('currency', {
  mounted(el) {
    const formatValue = (value) => {
      if (!value) return ''
      value = value.replace(/[^\d.]/g, '')
      if (value.startsWith('.')) {
        value = '0' + value
      }
      const parts = value.split('.')
      if (parts.length > 1) {
        return `${parts[0]}.${parts[1].substring(0, 18)}`
      }
      return parts[0]
    }

    el.addEventListener('input', () => {
      const value = el.value
      const formattedValue = formatValue(value)
      el.value = formattedValue
    })
  },
})
app.use(ElementPlus, {
  locale: zhCn,
  // large、default、small
  size: Cookies.get('size') || 'default',
})

app.mount('#app')
