import Decimal from 'decimal.js'
import TronWeb from 'tronweb/dist/TronWeb'
import { ethers } from 'ethers'

const AbiCoder = ethers.utils.AbiCoder
const ADDRESS_PREFIX_REGEX = /^(41)/
const ADDRESS_PREFIX = '41'
// 获取账户资源
const tronWeb = new TronWeb({ fullHost: 'https://api.trongrid.io' })

const toHex = (value) => {
  // Assuming value is a base58-encoded address
  // Use TronWeb to convert it to a hex address
  let hex = TronWeb.address.toHex(value)
  // Remove the '41' prefix
  if (hex.startsWith(ADDRESS_PREFIX)) {
    hex = '0x' + hex.slice(2)
  }
  return hex
}

const encodeParams = async (inputs) => {
  let typesValues = inputs
  let parameters = ''

  if (typesValues.length == 0) return parameters
  const abiCoder = new AbiCoder()
  let types = []
  const values = []

  for (let i = 0; i < typesValues.length; i++) {
    let { type, value } = typesValues[i]
    if (type == 'address') value = value.replace(ADDRESS_PREFIX_REGEX, '0x')
    else if (type == 'address[]') value = value.map((v) => toHex(v).replace(ADDRESS_PREFIX_REGEX, '0x'))
    types.push(type)
    values.push(value)
  }

  try {
    parameters = abiCoder.encode(types, values).replace(/^(0x)/, '')
  } catch (error) {
    console.error(error)
  }
  return parameters
}

const toDecimal = (val) => new Decimal(val)

export const tronAddressToHex = (tronAddress) => TronWeb.address.toHex(tronAddress)

export const getTronLinkTronWebProvider = () => window?.tronLink

export const newTronWebProvider = (options) => new TronWeb(options)

const isHexReg = /^[0-9a-fA-F]{64}$/

const estimateBandwidth = (signedTxn) => {
  var DATA_HEX_PROTOBUF_EXTRA = 3
  var MAX_RESULT_SIZE_IN_TX = 64
  var A_SIGNATURE = 67

  var len = signedTxn.raw_data_hex.length / 2 + DATA_HEX_PROTOBUF_EXTRA + MAX_RESULT_SIZE_IN_TX
  var signatureListSize = signedTxn.signature.length
  for (let i = 0; i < signatureListSize; i++) {
    len += A_SIGNATURE
  }
  return len
}

const estimateBandwidth2 = (txn) => {
  const DATA_HEX_PROTOBUF_EXTRA = 3
  const MAX_RESULT_SIZE_IN_TX = 64
  const A_SIGNATURE = 67

  let len = txn.raw_data_hex.length / 2 + DATA_HEX_PROTOBUF_EXTRA + MAX_RESULT_SIZE_IN_TX

  let signatureListSize = txn.signature ? txn.signature.length : 0

  if (signatureListSize === 0) {
    len += A_SIGNATURE
  } else {
    for (let i = 0; i < signatureListSize; i++) {
      len += A_SIGNATURE
    }
  }
  return len
}

const sleep = (timeout) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })

const triggerConstantContract = async ({ contractAddress, functionSelector, options, parameters, estimateAddress }) => {
  let attemptTimes = 1
  let unsignedTx
  while (attemptTimes <= 5) {
    try {
      unsignedTx = await tronWeb.transactionBuilder.triggerConstantContract(
        contractAddress,
        functionSelector,
        options,
        parameters,
        estimateAddress,
      )
    } catch (error) {
      console.error(`++++++[${new Date().toISOString()}] triggerConstantContract`, error)
    }
    if (unsignedTx?.result?.result === true) {
      return Promise.resolve(unsignedTx)
    }
    await sleep(888)
    attemptTimes++
  }
  return Promise.resolve()
}

/**
 * Estimate batch transfer energy
 * 1 获取账户资源失败
 * 2 构建合约交易失败
 * @returns
 */
export const estimateBatchTransferEnergy = async ({
  contractAddress,
  functionSelector,
  parameters,
  callValue,
  senderAddress,
  estimateAddress,
}) => {
  const ret = {
    code: -1,
    msg: '操作失败',
    data: null,
    error: null,
  }

  let resources
  try {
    resources = await tronWeb.trx.getAccountResources(senderAddress)
  } catch (error) {
    ret.code = 1
    ret.error = error
    ret.msg = '获取账户资源失败'
    return ret
  }

  const freeBandwidthBalance = toDecimal(resources.freeNetLimit).minus(toDecimal(resources?.freeNetUsed ?? 0))
  const obtainedByStakingTRXBandwidth = toDecimal(resources?.NetLimit || 0).minus(toDecimal(resources?.NetUsed || 0))
  const totalBandwidth = toDecimal(resources.freeNetLimit).plus(toDecimal(resources?.NetLimit || 0))
  const useableEnergy = toDecimal(resources?.EnergyLimit || 0).minus(toDecimal(resources?.EnergyUsed || 0))
  const useableBandwidth = freeBandwidthBalance.plus(obtainedByStakingTRXBandwidth)

  // 模拟合约调用
  const unsignedTx = await triggerConstantContract({
    contractAddress,
    functionSelector,
    options: {
      // 100000 trx
      feeLimit: toDecimal(100000).times(Decimal.pow(10, 6)).toNumber(),
      callValue: callValue,
    },
    parameters,
    estimateAddress,
  })
  if (unsignedTx?.result?.result !== true) {
    ret.code = 2
    ret.msg = '构建合约交易失败'
    return ret
  }

  const estimateBandwidthRet = toDecimal(estimateBandwidth2(unsignedTx.transaction))
  const estimatedEnergy = toDecimal(unsignedTx.energy_used || 0)

  let totalTrx = toDecimal(0)
  let isAccountNeedEnergy = false

  // 计算带宽费用
  let bandwidthTrx = toDecimal(0)
  if (useableBandwidth.lessThan(estimateBandwidthRet)) {
    isAccountNeedEnergy = true
    bandwidthTrx = estimateBandwidthRet.times(toDecimal(0.001))
    totalTrx = totalTrx.plus(estimateBandwidthRet.minus(useableBandwidth).times(toDecimal(0.001)))
  }

  // 计算能量费用
  let leftEnergyInTrx = toDecimal(0)
  let leftEnergy = toDecimal(0)
  const energyPrice = toDecimal(420) // 假设能量价格为 420 sun/energy
  if (useableEnergy.lessThan(estimatedEnergy)) {
    leftEnergy = estimatedEnergy.minus(useableEnergy)
    leftEnergyInTrx = leftEnergy.times(toDecimal(0.00042))
    totalTrx = totalTrx.plus(leftEnergy.times(energyPrice).dividedBy(1000000))
  }
  // 返回均为 decimal 对象
  ret.data = {
    // 是否需要租赁
    isAccountNeedEnergy,
    // 可用带宽
    useableBandwidth: useableBandwidth,
    // 带宽消耗
    bandwidthCost: estimateBandwidthRet,
    // 带宽消耗折算 trx
    bandwidthTrx,
    // 总带宽，不包括可用
    totalBandwidth,

    // 预估本次转账能量消耗
    estimatedEnergy: estimatedEnergy,

    // 本次转账总 trx 消耗
    totalTrxCost: totalTrx,
    // 可用能量
    useableEnergy,
    // 需要租赁能量的部分折算 trx，直接用 trx 抵扣
    leftEnergyInTrx,
    // 需要租赁能量的部分
    leftEnergy,
  }
  ret.code = 0
  ret.msg = '成功'
  return ret
}

export const handleTronError = (error) => {
  if (!error) return '请去钱包查看'

  const errorMessage = typeof error === 'string' ? error : error?.message
  if (!errorMessage) return '请去钱包查看'

  const lowerCaseError = errorMessage.toLowerCase()

  const errorMap = {
    'confirmation declined by user': '用户取消交易',
    insufficient: 'gas不足',
    'balance is not sufficient': 'gas不足',
    bandwidth: '带宽不足',
    energy: '能量不足',
    'revert opcode executed': '合约执行失败',
    'network error': '网络连接错误',
    timeout: '交易超时',
    'invalid address': '无效的地址',
    'duplicate transaction': '重复的交易',
    'pool is full': '交易池已满，请稍后重试',
  }

  for (const [key, value] of Object.entries(errorMap)) {
    if (lowerCaseError.includes(key)) return value
  }

  return '请去钱包查看'
}
