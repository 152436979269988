// import { createI18n } from 'vue-i18n'
// import axios from 'axios'
// import { ElMessage } from 'element-plus'
// import copyToClipboard from 'copy-to-clipboard'
// import * as lodash from 'lodash'
// import dayjs from 'dayjs'
// import { ethers } from 'ethers'
// import Decimal from 'decimal.js'
// import TronWeb from 'tronweb/dist/TronWeb'
// import numeral from 'numeral'
// import * as Sentry from '@sentry/vue'

import {
  createI18n,
  axios,
  ElMessage,
  copyToClipboard,
  lodash,
  dayjs,
  ethers,
  Decimal,
  numeral,
  TronWeb,
  Sentry,
  xlsx,
  iconv,
} from './libs'

// import { storageAppLocale } from '@/constant'
import zh_CN from './locales/zh-cn'
import en_US from './locales/en'
import zh_TW from './locales/zh-tw'

import * as utilViem from './util_viem'
import * as constant from './constant'
import * as utilEthereum from './util_ethereum'
import * as utilTron from './util_tron'
import * as utilAnkr from './util_ankr'
import BatchTransferABi from './json/batch_transfer.json'
import Erc20ABi from './json/erc_20.json'

Decimal.set({ toExpNeg: -100, toExpPos: 100 })

const customLog = (message, level = 'info', extras = {}) => {
  if (env.viteIsProd) {
    Sentry.withScope((scope) => {
      Object.entries(extras).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
      // @ts-ignore
      Sentry.captureMessage(message, level)
    })
  }

  // 可以同时在控制台输出，方便本地调试
  console.log(`[${level.toUpperCase()}]`, message, extras)
}

export const toDecimal = (val) => {
  return new Decimal(val)
}

const abis = {
  BatchTransferABi,
  Erc20ABi,
}

// export { dayjs }
export {
  ethers,
  TronWeb,
  dayjs,
  utilViem,
  constant,
  utilEthereum,
  utilTron,
  utilAnkr,
  Decimal,
  abis,
  lodash,
  numeral,
  Sentry,
  customLog,
  xlsx,
  iconv,
}

// Constant
// export const constant = {
//   storageAppLocale: 'APP_LOCALE',
// }

// Env
export const env = {
  viteAppEnv: import.meta.env.VITE_APP_ENV,
  viteSiteName: import.meta.env.VITE_SITE_NAME,
  viteBaseUrl: import.meta.env.VITE_BASE_URL,
  viteSiteIcon: import.meta.env.VITE_SITE_ICON,
  viteBuildCompress: import.meta.env.VITE_BUILD_COMPRESS,
  // @ts-ignore
  // viteIsProd: import.meta.env.PROD === 'production',
  // viteIsProd: import.meta.env.PROD,
  viteIsProd: import.meta.env.VITE_APP_ENV === 'production',
  viteAnkrApiKey: import.meta.env.VITE_ANKR_API_KEY,
  viteTronGridKeyList: import.meta.env.VITE_TRONGRID_API_KEY.split(','),
  viteAnkrAdvancedApi: import.meta.env.VITE_ANKR_ADVANCED_API,
  viteTronRestHttpsEndpoint: import.meta.env.VITE_TRON_REST_HTTPS_ENDPOINT,
  viteTronEstimateAddress: import.meta.env.VITE_TRON_ESTIMATE_ADDRESS,
}

// I18n
const onGetEnvLocale = () => {
  let localeKey = null
  let localeName = null
  const query = location.href
  let vars = query.split('lang=')
  if (vars && vars.length > 0 && vars[1] == 'zh_CN') {
    localeKey = 'zh-CN'
    localeName = '简体中文'
  } else if (vars && vars.length > 0 && vars[1] == 'en') {
    localeKey = 'en'
    localeName = 'English'
  } else if (vars && vars.length > 0 && vars[1] == 'zh_TW') {
    localeKey = 'zh-TW'
    localeName = '繁体中文'
  }
  // if (!localeKey) {
  //   const appLocale = storage.getStorageSync(storageAppLocale, true)
  //   if (appLocale) {
  //     localeKey = appLocale.localeKey
  //     localeName = appLocale.localeName
  //   }
  // }

  if (!localeKey) {
    // @ts-ignore
    let lang = navigator.language || navigator?.userLanguage
    lang = lang.substr(0, 2)
    if (lang === 'zh') {
      localeKey = 'zh-CN'
      localeName = '简体中文'
    } else if (lang === 'en') {
      localeKey = 'en'
      localeName = 'English'
    } else if (lang === 'zh-TW') {
      localeKey = 'zh-TW'
      localeName = '繁体中文'
    }
  }

  if (!localeKey) {
    localeKey = import.meta.env.VITE_DEFAULT_LOCALE_KEY
    localeName = import.meta.env.VITE_DEFAULT_LOCALE_NAME
  }

  return {
    localeKey,
    localeName,
  }
}

export const { localeKey, localeName } = onGetEnvLocale()

export const i18n = createI18n({
  locale: localeKey,
  globalInjection: true,
  fallbackLocale: 'en',
  silentTranslationWarn: env.viteIsProd,
  messages: {
    en: en_US,
    'zh-CN': zh_CN,
    'zh-TW': zh_TW,
  },
})
export const t = i18n.global.t

// Tools
export const tool = {
  removeEmptyKey: (obj = {}, remove = true) => {
    for (const [key, value] of Object.entries(obj)) {
      if (lodash.isNil(value) || value === '') {
        remove ? delete obj[key] : (obj[key] = undefined)
      }
      if (lodash.isPlainObject(value)) {
        tool.removeEmptyKey(value)
      }
    }
    return obj
  },

  isValidUrl: (urlString) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // validate fragment locator
    return !!urlPattern.test(urlString)
  },
  onCopyToClipboard: (text, successCb, failCb) => {
    const flag = copyToClipboard(text)
    if (flag) {
      successCb && successCb()
    } else {
      failCb && failCb()
    }
    return flag
  },
  formatAddress: (str = '', prefixLength = 6, suffixLength = 4) => {
    const regex = new RegExp(`(.{${prefixLength}}).*(.{${suffixLength}})`)
    return str.replace(regex, '$1...$2')
  },
  message: {
    success: (message) =>
      ElMessage({
        type: 'success',
        message,
      }),
    warning: (message) =>
      ElMessage({
        type: 'warning',
        message,
      }),
    info: (message) =>
      ElMessage({
        type: 'info',
        message,
      }),
    error: (message) =>
      ElMessage({
        type: 'error',
        message,
      }),
  },
  sleep: (timeout) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, timeout)
    }),
}

// Apis
export const createRequest = (axiosOptions, createOptions) => {
  createOptions = Object.assign(
    {
      packErr: true,
    },
    createOptions,
  )
  const { packErr = true } = createOptions

  const instance = axios.create(
    Object.assign(
      {
        withCredentials: false,
        timeout: 90000,
      },
      axiosOptions,
    ),
  )

  instance.interceptors.request.use(
    (config) => {
      if (config.method.toUpperCase() === 'GET') {
        tool.removeEmptyKey(config.params, false)
      } else {
        tool.removeEmptyKey(config.data, false)
      }
      return config
    },
    (error) => Promise.reject(error),
  )

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      console.error(error)
      const ret = {
        success: false,
        code: -1,
        error: (error?.toJSON && error?.toJSON()) || error,
      }
      if (error.response) {
        const { data } = error.response
        ret['code'] = data.code || 500
        ret['msg'] = data.msg || data.message || 'Server response error'
        ret['response'] = error.response
      } else if (error.request) {
        ret['code'] = 400
        ret['msg'] = error.message || 'Network error'
      } else {
        ret['code'] = 400
        ret['msg'] = error.message || 'App error'
      }
      if (packErr) {
        return Promise.resolve(ret)
      } else {
        return Promise.reject(ret)
      }
    },
  )

  return instance
}
const jsonReq = createRequest({
  baseURL: env.viteBaseUrl,
})

export const api = {
  // 获取充值地址
  getAddressApi: (data) => jsonReq({ url: '/api/index/getAddress', method: 'POST', data }),
  // 获取价格接口
  // 参数 num       需要的能量数量
  // 参数 count_num   用户转帐账户数量的和
  getPriceApi: (data) => jsonReq({ url: '/api/index/getPrice', method: 'POST', data }),
  // 下单接口
  /**
   * 参数 order_id       订单号
参数 rental_quantity      租借数量
参数 account_count      用户转帐账户数量的和
参数 order_amount       订单金额
参数 order_address      用户钱包地址
参数 transaction_hash   转账的交易hash地址
参数 status 支付状态 0 未支付  1支付
   */
  addOrderApi: (data) => jsonReq({ url: '/api/index/addOrder', method: 'POST', data }),
  // 确认支付接口
  /**
   * 
   * 参数 order_id  订单号
参数 order_address 用户钱包地址
参数 status 支付状态 0 未支付  1支付
参数 transaction_hash 装涨的交易hash地址
   */
  updateOrderStatusApi: (data) => jsonReq({ url: '/api/index/updateOrderStatus', method: 'POST', data }),
}

// Store
export const store = {
  useCounterStore: defineStore('counter', () => {
    const count = ref(0)
    const doubleCount = computed(() => count.value * 2)
    function increment() {
      count.value++
    }

    return { count, doubleCount, increment }
  }),
}
